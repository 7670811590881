@media all {

    .vendor-modal {
        margin: 20px;
    }

    .vendor-modal-logo {
        max-width: 150px;
    }

    h1 {
        color: #ff8040;
    }

    .version {
        font-size: 0.8rem;
    }
}