
.create-button {
    margin-top: 10px;
}

.create-button > label {
    padding-left: 7px;
}

.role-edit-item {
    margin-left: 15px;
}

.useradmin-modal-buttons {
    height: 2.5em;
    float: right;
    margin-bottom: 10px;
}

.useradmin-modal-buttons > .btn-primary {
}

.useradmin-modal-buttons > .btn-secondary {
    margin-right: 10px;
}