.App {
    text-align: center;
}

.bg-image {
    background-position: center!important;
    background-size: cover!important;
    background-repeat: no-repeat!important;
    height: 100vh;
}

@media all and (min-width: 768px) {
    .App-content {
        top: 60px;
        position: absolute;
        width: 100%;
    }
}

.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
    background-color: rgba(182, 27, 27, 1) !important;
    border-color: rgb(154, 21, 21) !important;;
}