@media all {

    .toast-disabled {
        height: 0;
    }

    .show-toast {
        position: absolute;
        top: 10px;
        left: 50%;
        right: auto;
        transform: translate(-50%, 0px);
        background-color: rgba(182, 27, 27, 1);
        border-color: rgb(154, 21, 21);
        color: white;
        text-align: center;
        z-index: 1000;
        min-width: 240px;
        border-radius: 4px;
    }

}