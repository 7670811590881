@media all and (min-width: 768px) {
    .navbar-wrapper {
        background-color: #fff;
        position: fixed;
        z-index: 800;
        width: 100%;
    }

    .navbar-menu {
        right: 15px;
        position: absolute;
    }

}