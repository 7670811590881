.course-edit-item {
    margin-left: 15px;
}

.notification-checkbox {
    max-height: 1rem;
    max-width: 1rem;
}

.course-list-item {
    height: auto !important;
}

.course-list-wrapper {
    border: solid;
    border-color: lightgray;
    max-height: 16em;
    max-width: 35em;
    overflow-y: auto;
}
.overview-notification {
    margin-right: 1rem;
}

.settings-form-checkbox {
    max-height: 3rem;
}

.settings-modal-buttons > .btn-primary {
    position: absolute;
    top: 15px;
    right: 20px;
    width: 40px;
    height: 40px;
}

.settings-modal-buttons > .btn-secondary {
    position: absolute;
    top: 15px;
    right: 75px;
    width: 40px;
    height: 40px;
}